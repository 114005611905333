import Login from "@/views/Login/Index";
import Register from "@/views/Register/Index";
import Forget from "@/views/Forget/Index";
import MainLayout from "@/views/MainLayout/Index";
import Home from "@/views/Home/Index";


export default [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/forget",
    component: Forget,
  },
  // {
  //   path: "/home",
  //   component: Home,
  // },


  {
    path: "/main-layout",
    component: MainLayout,
    children: [
      {
        path: "home",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/Home/Index"),
      },
      {
        path: "meeting-message",
        name: "MeetingMessage",
        component: () =>
          import(/* webpackChunkName: "MeetingMessage" */ "@/views/MeetingMessage/Index"),
      },
      {
        path: "history",
        name: "History",
        component: () =>
          import(/* webpackChunkName: "History" */ "@/views/History/Index"),
      },
      {
        path: "meeting-message",
        name: "MeetingMessage",
        component: () =>
          import(/* webpackChunkName: "MeetingMessage" */ "@/views/MeetingMessage/Index"),
      },
      // {
      //   path: "person-info",
      //   name: "PersonInfo",
      //   meta: {
      //     icon: "",
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "PersonInfo" */ "@/views/PersonInfo/Index"),
      // },
      // {
      //   path: "team",
      //   name: "Team",
      //   meta: {
      //     icon: "",
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "Team" */ "@/views/Team/Index"),
      // },
    ],
  },
];
