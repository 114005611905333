import toFormData from "./toFormData";
import downloadByUrl from "./downloadByUrl";
import bus from "./bus";

export default {
  getBaseUrl() {
    return process.env.NODE_ENV === "development" ? "proxy" : "";
  },
  toFormData,
  downloadByUrl,
  bus
};
