<template>
  <!-- 2023-10-30 注 -->
  <div
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.4)"
    element-loading-text="会议进入中..."
    id="app1"
    class="video-metting-box"
    :class="themeClass"
  >
    <!-- <GlobalTransition>
      <router-view />
    </GlobalTransition> -->
    <!-- <a href="#" onclick="unload()">Unload</a>
    <a href="#" onclick="switchVideo()">switchVideo</a>
    <div id="audioOutputSelectWrapper" style="display: none">
      Change audio output device
      <select
        id="audioOutputSelect"
        onchange="changeAudioOutput(this)"
      ></select>
    </div> -->
    <!-- {{ message }} -->
    <div id="meet" style="width: 100%; height: 100%"></div>
    <el-badge
      v-if="QuickMeetingProps.waiting_room && isModerator"
      :value="waitingRoomNumber"
      class="waiting-room-badge"
    >
      <el-button type="primary" size="small" @click="openWaitingRoom"
        >等候室</el-button
      >
    </el-badge>
    <!-- 会议邀请 -->
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <MeetingInvites
        v-if="MeetingInvitesdialogOptions.visible"
        :options="MeetingInvitesdialogOptions"
        :row="QuickMeetingProps"
      />
    </transition>
    <!-- 等候室 -->
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <WaitingRoom
        v-if="waitingRoomdialogOptions.visible"
        :options="waitingRoomdialogOptions"
        :list="waitRoomlist"
        :showClose="true"
      />
    </transition>
  </div>
</template>

<script>
import theme from "@/mixins/theme";
import MeetingInvites from "@/views/Components/MeetingInvites.vue";
import WaitingRoom from "@/views/Home/components/WaitingRoom.vue";
import {
  likaiMeeting,
  endMeeting,
  meetingDetail,
  meeting_transcribe_add,
  meetingWaitingArea, //等候区域列表
} from "@/api/home.js";
export default {
  components: {
    MeetingInvites,
    WaitingRoom,
  },
  mixins: [theme],
  props: {
    // quickMeetingRoomNumber: {
    //   type: String,
    //   default() {
    //     return "test";
    //   },
    // },
    // quickMeetingRoomTitle: {
    //   type: String,
    //   default() {
    //     return "test";
    //   },
    // },
    QuickMeetingProps: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 等候人数
      waitingRoomNumber: 0,
      loading: true,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      search: "",
      message: "Hello",
      api: null,
      // room: "test_romm",
      username: "",
      isModerator: false, //是否为主持人
      // 点击离开
      isLeave: false,
      //点击结束
      isOver: false,
      // 是否加入讨论组
      isJoinedBreakoutRoom: false,
      // 显示会议邀请弹窗
      MeetingInvitesdialogOptions: {
        title: "",
        visible: false,
        disabled: false,
        size: "small-dialog-box",
      },
      // 显示等候室弹窗
      waitingRoomdialogOptions: {
        title: "",
        visible: false,
        disabled: false,
        number: "",
        isInMeeting: true, // 会议中显示入会邀请按钮
        size: "small-dialog-box",
      },
      waitRoomlist: [],
    };
  },
  created() {
    this.username = this.QuickMeetingProps.nickname || this.userInfo.nickname;
    console.log(
      "🚀 ~ created ~ this.QuickMeetingProps会议房间信息:",
      this.QuickMeetingProps
    );

    // window.addEventListener("resize", this.resizeChart);
    this.init();
  },
  mounted() {
    this.openRoom();
  },
  beforeDestroy() {
    this.intervalId && clearInterval(this.intervalId);
  },
  methods: {
    init() {
      if (this.QuickMeetingProps.waiting_room) {
        //获取等候室人员数量
        this.getWaitRoomList();

        this.intervalId = setInterval(() => {
          this.getWaitRoomList();
        }, window.g.intervalTime || 3000);
      }
    },
    //获取等候室人员数量
    getWaitRoomList() {
      const params = {
        number: this.QuickMeetingProps.quickMeetingRoomNumber,
      };
      meetingWaitingArea(params)
        .then((res) => {
          console.log("🚀 ~ meetingWaitingArea ~ res:", res);
          // const list = [];
          // for (let i = 0; i < 20; i++) {
          //   list.push(res.data[0]);
          // }
          // this.list = list;
          this.waitRoomlist = res.data;
          this.waitingRoomNumber = res.data.length;

          this.waitRoomlist.forEach((item) => {
            if (item.user_id == this.userInfo.id && item.status == 0) {
              console.log("2222");
              // 会议内被请求离开
              this.$alert("您已被请出会议", "提示", {
                confirmButtonText: "确定",
              });

              this.$emit("onleave");
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openRoom() {
      // verify the JitsiMeetExternalAPI constructor is added to the global..
      if (window.JitsiMeetExternalAPI) {
        // var person = prompt("Please enter your name:", "Rabie");
        // if (person != null || person != "") this.username = person;
        // var room = prompt("Please enter your room:", "Test Room");
        // if (room != null || room != "") this.room = room;
        // this.username = this.username;
        this.startConference();

        this.isModerator = this.QuickMeetingProps.user_id == this.userInfo.id;
      } else {
        this.loading = false;
        alert("加载失败");
      }
      // } else alert("Jitsi Meet API script not loaded");
    },
    openWaitingRoom() {
      this.waitingRoomdialogOptions.visible = true;
      this.waitingRoomdialogOptions.title =
        this.QuickMeetingProps.quickMeetingRoomTitle + "等候室";
      this.waitingRoomdialogOptions.number =
        this.QuickMeetingProps.quickMeetingRoomNumber;
    },
    // 会议邀请
    MeetingInvites() {
      console.log("会议邀请");
      this.MeetingInvitesdialogOptions.title = "会议邀请";
      this.MeetingInvitesdialogOptions.visible = true;
    },

    startConference() {
      try {
        const domain = window.g.domain;
        console.log("🚀 ~ startConference ~ domain:", domain);
        const options = {
          // lang: "de",
          lang: "zhCN",
          roomName: this.QuickMeetingProps.quickMeetingRoomNumber, //要加入的房间名称
          height: "100%", //创建的IFrame高度
          parentNode: document.querySelector("#meet"), //将 IFrame 添加为子元素的 HTML DOM 元素
          // onload: this.onMeetLoad,
          // 覆盖interface_config.js文件中定义的选项的 JS 对象。
          interfaceConfigOverwrite: {
            DEFAULT_BACKGROUND: "rgba(64, 158, 255, 0.2)",
            // DEFAULT_WELCOME_PAGE_LOGO_URL: "/svg/mute.svg",
            // BRAND_WATERMARK_LINK: "https://www.baidu.com",
            JITSI_WATERMARK_LINK: "https://www.baidu.com",
            // filmStripOnly: false,
            // SHOW_BRAND_WATERMARK: true,
            SHOW_JITSI_WATERMARK: false,
            TILE_VIEW_MAX_COLUMNS: 3, //配置平铺视图中的最大列数,接受 1 到 5 之间的值。默认值为 5。
          },
          // configOverwrite 覆盖config.js文件中定义的选项的 JS 对象。
          configOverwrite: {
            disablePolls: true, // 禁用投票
            testing: {
              disableE2EE: true, // 禁用e2ee
            },
            gravatar: {
              disabled: true, // 禁用gravatar
            },
            welcomePage: {
              // Whether to disable welcome page. In case it's disabled a random room
              // will be joined when no room is specified.
              disabled: true,
            },
            disableVirtualBackground: true, // 禁用虚拟背景
            // disableSimulcast: false,
            startWithAudioMuted: !this.QuickMeetingProps.is_speech, //加入时关闭音频输入
            startWithVideoMuted: !this.QuickMeetingProps.is_camera, //加入时关闭视频输入
            // disableInitialGUM: true,
            // hideDisplayName: true,
            // defaultAvatarUrl: window.g.avatarBaseUrl + this.userInfo.avatar1,
            readOnlyName: true,
            // 加入会议准备界面
            prejoinConfig: {
              // When 'true', it shows an intermediate page before joining, where the user can configure their devices.
              // This replaces `prejoinPageEnabled`.
              enabled: false,
              // Hides the participant name editing field in the prejoin screen.
              // If requireDisplayName is also set as true, a name should still be provided through
              // either the jwt or the userInfo from the iframe api init object in order for this to have an effect.
              // hideDisplayName: false,
              // List of buttons to hide from the extra join options dropdown.
              // hideExtraJoinButtons: ["no-audio", "by-phone"],
            },
            disableReactions: true,
            // toolbarButtons: ["camera"],
            // 设置底部功能按钮
            toolbarButtons: [
              "camera",
              "chat",
              // "closedcaptions",
              "desktop",
              "download",
              // "embedmeeting",
              "etherpad",
              "feedback",
              "filmstrip",
              // "fullscreen",
              "hangup",
              // "help",
              "highlight",
              // "invite",
              "linktosalesforce",
              "livestreaming",
              "microphone",
              "noisesuppression",
              "participants-pane",
              // "profile",
              "raisehand",
              "recording",
              // "security",
              // "select-background",
              // "settings",
              // "shareaudio",
              // "sharedvideo",
              // "shortcuts",
              // "stats",
              "tileview",
              "toggle-camera",
              "videoquality",
              // "whiteboard",
              "customInviteButton",
            ],
            // defaultLogoUrl: '/svg/mute.svg'
          },
          userInfo: {
            // email: "222mail@jitsiexamplemail.com",
            // email: this.userInfo.id,
            displayName: this.username,
            // id: 123123213,
          },

          // devices置呼叫的初始媒体设备
          devices: {
            // audioInput: "<deviceLabel>",
            // audioOutput: "<deviceLabel>",
            // videoInput: "<deviceLabel>",
          },
        };

        // 创建 Jitsi Meet API 对象  domain：用于构建会议 URL 的域（例如，meet.jit.si）。options：具有属性的对象。
        this.api = new JitsiMeetExternalAPI(domain, options);
        this.api.executeCommand(
          "avatarUrl",
          window.g.avatarBaseUrl + this.userInfo.avatar1
        );
        this.api.executeCommand("displayName", this.username); //名字

        // 头像变更
        this.api.addEventListener("avatarChanged", (obj) => {
          console.log(
            "🚀 ~ file: AppVideo.vue:123 ~ this.api.addEventListener ~ obj:",
            obj,
            "头像变更"
          );

          this.loading = false;
        });

        // 监听录制状态
        this.api.addEventListener("recordingStatusChanged", async (obj) => {
          console.log(
            "🚀 ~ this.api.addEventListener ~ recordingStatusChanged:",
            obj
          );
          if (obj.on) {
            // 开启录制

            const roomsRes = await this.api.getRoomsInfo();
            const rooms = roomsRes.rooms;

            // 判断当前用户是否为主持人
            let isModerator = false;

            for (let i = 0; i < rooms.length; i++) {
              const room = rooms[i];
              const roomNumber = room.id.split("@").shift();

              if (
                roomNumber === this.QuickMeetingProps.quickMeetingRoomNumber
              ) {
                for (let j = 0; j < room.participants.length; j++) {
                  const item = room.participants[j];

                  const avatarEnd = item.avatarUrl.split("/").pop();
                  const avatarEnd1 = this.userInfo.avatar1.split("/").pop();

                  if (avatarEnd === avatarEnd1) {
                    isModerator = true;
                    break;
                  }
                }
              }
            }

            if (isModerator) {
              const params = {
                number: this.QuickMeetingProps.quickMeetingRoomNumber,
                url: obj.sessitionId,
              };

              meeting_transcribe_add(params).then((res) => {
                console.log("开启录制成功", res);
              });
            }
          }
        });
        // 视频会议已加入
        this.api.addEventListener("videoConferenceJoined", (obj) => {
          // console.log("Local User Joined", obj);
          console.log(
            " this.QuickMeetingProps.waiting_room是否开启等候室",
            this.QuickMeetingProps.waiting_room
          );
          //开启大厅模式
          // () => {
          //   api.executeCommand(
          //     "toggleLobby",
          //     this.QuickMeetingProps.waiting_room
          //   );
          // };
          // this.api.executeCommand(
          //   "toggleLobby",
          //   this.QuickMeetingProps.waiting_room
          // );
          // this.api.executeCommand(
          //   "avatarUrl",
          //   "https://avatars0.githubusercontent.com/u/3671647"
          // );
          // this.api.executeCommand("password", "123456");
          // this.api.executeCommand("displayName", obj.displayName); //名字
          // this.loading = false;
        });
        // 已加入参与者
        this.api.addEventListener("participantJoined", (obj) => {
          console.log(
            "🚀 ~ file: AppVideo.vue:123 ~ this.api.addEventListener ~ obj:",
            obj,
            "已加入参与者信息"
          );
        });
        // 会议邀请
        this.api.addEventListener("clickCustomInviteBtn", (obj) => {
          console.log("会议邀请");
          this.MeetingInvitesdialogOptions.title = "会议邀请";
          this.MeetingInvitesdialogOptions.visible = true;
          console.log(
            "🚀 ~ this.api.addEventListener ~ clickCustomInviteBtn:",
            obj,
            "邀请"
          );
          console.log(
            "🚀 ~ created ~ this.QuickMeetingProps会议房间信息:",
            this.QuickMeetingProps
          );
        });
        // 当前参与者信息
        this.api.addEventListener("participantRoleChanged", (obj) => {
          console.log(
            "🚀 ~ file: AppVideo.vue:123 ~ this.api.addEventListener ~ obj:",
            obj,
            "参与者信息修改"
          );
        });
        // 下面两个方法不生效
        // // 参与者被批准
        // this.api.addEventListener("moderationParticipantApproved", (obj) => {
        //   console.log(
        //     "🚀 ~ file: AppVideo.vue:123 ~ this.api.addEventListener ~ obj:",
        //     obj,
        //     "参与者被批准"
        //   );
        // });
        // // 参与者被拒绝
        // this.api.addEventListener("moderationParticipantRejected", (obj) => {
        //   console.log(
        //     "🚀 ~ file: AppVideo.vue:456 ~ this.api.addEventListener ~ obj:",
        //     obj,
        //     "参与者被拒绝"
        //   );
        // });
        // 通知触发
        this.api.addEventListener("notificationTriggered", (obj) => {
          if (obj.title == "lobby.joinRejectedTitle") {
            console.log("申请被拒绝");
            this.$message({
              message: "申请被拒绝",
              type: "error",
              duration: 1500,
            });
            this.$emit("onleave");
          }
          console.log(
            "🚀 ~ file: AppVideo.vue:789 ~ this.api.addEventListener ~ obj:",
            obj,
            obj.title,
            "通知触发"
          );
        });

        // 视频会议参与者离开
        this.api.addEventListener("videoConferenceLeft", (obj) => {
          console.log(
            "离开了会议111111111111111",
            obj,
            this.isJoinedBreakoutRoom
          );
          // this.api.executeCommand("endConference");
          if (
            this.isOver ||
            this.isJoinedBreakoutRoom ||
            this.QuickMeetingProps.quickMeetingRoomNumber != obj.roomName
          ) {
            console.log("先不离开");
            return false;
          } else {
            this.$emit("onleave");
            const params = {
              number: this.QuickMeetingProps.quickMeetingRoomNumber,
              user_id: this.userInfo.id,
            };
            likaiMeeting(params)
              .then((res) => {
                console.log("🚀 ~ .then ~ res:离开会议1", res);
                // this.$emit("onleave");
              })
              .finally(() => {});
          }
        });
        // 加入分组讨论室
        this.api.addEventListener("clickAddBreakoutRoomBtn", (obj) => {
          console.log("clickAddBreakoutRoomBtn--------", obj);
          this.isJoinedBreakoutRoom = true;
        });
        // 离开分组讨论室
        this.api.addEventListener("clickLeaveBreakoutRoomBtn", (obj) => {
          console.log("clickLeaveBreakoutRoomBtn--------", obj);
          this.isJoinedBreakoutRoom = false;
        });
        // 视频会议准备关闭
        this.api.addEventListener("readyToClose", (obj) => {
          console.log("视频会议准备关闭22222222222222", obj);
          this.$emit("onleave");
          const params = {
            number: this.QuickMeetingProps.quickMeetingRoomNumber,
            user_id: this.userInfo.id,
          };
          likaiMeeting(params)
            .then((res) => {
              console.log("🚀 ~ .then ~ res:离开会议2", res);
              // this.$emit("onleave");
            })
            .finally(() => {});
          // meetingDetail({
          //   id: this.QuickMeetingProps.quickMeetingRoomNumber,
          // }).then((res) => {
          //   console.log(res.data.user_id, this.userInfo.id, "看是不是主持人");
          //   if (res.data.user_id == this.userInfo.id) {
          //     endMeeting({ id: res.data.id }).then((res) => {
          //       console.log("会议结束");
          //     });
          //   } else {
          //     const params = {
          //       number: this.QuickMeetingProps.quickMeetingRoomNumber,
          //       user_id: this.userInfo.id,
          //     };
          //     likaiMeeting(params)
          //       .then((res) => {
          //         // this.$emit("onleave");
          //       })
          //       .finally(() => {});
          //   }
          //   console.log("会议详情", res);
          // });

          // this.$emit("onleave");
        });
        // 点击离开会议按钮(讨论组)
        this.api.addEventListener("clickLeaveBtn", (obj) => {
          this.isLeave = true;
          console.log("clickLeaveBtn obj是房间号", obj);
          console.log(
            "🚀 ~ this.api.addEventListener ~ this.isJoinedBreakoutRoom:",
            this.isJoinedBreakoutRoom
          );
          // if (this.QuickMeetingProps.quickMeetingRoomNumber != obj.roomName) {
          //   return false;
          // }
          // 当在分组讨论室时离开会议
          if (this.isJoinedBreakoutRoom) {
            this.$emit("onleave");
            const params = {
              number: this.QuickMeetingProps.quickMeetingRoomNumber,
              user_id: this.userInfo.id,
            };
            likaiMeeting(params)
              .then((res) => {
                console.log("🚀 ~ .then ~ res:离开会议3", res);
                // this.$emit("onleave");
              })
              .finally(() => {});
          }
        });
        // 点击结束会议按钮
        this.api.addEventListener("clickEndBtn", (obj) => {
          this.isOver = true;
          console.log("clickEndBtn", obj);
          meetingDetail({
            id: this.QuickMeetingProps.quickMeetingRoomNumber,
          }).then((res) => {
            console.log("会议详情", res);
            // console.log(res.data.user_id, this.userInfo.id, "看是不是主持人");
            // if (res.data.user_id == this.userInfo.id) {
            endMeeting({ id: res.data.id }).then((res) => {
              console.log("会议结束");
            });
            // }
          });
        });
      } catch (error) {
        console.error("Failed to load Jitsi API", error);
      }
    },
    onMeetLoad() {
      console.log("🚀 ~ onMeetLoad ~ onMeetLoad:");
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.video-metting-box {
  width: 100% !important;
  height: 100% !important;

  .leftwatermark {
    display: none !important;
  }

  .waiting-room-badge {
    position: fixed;
    z-index: 999;
    left: px2vh(20);
    top: px2vh(20);
  }
}
</style>
