<!-- 忘记密码 -->
<template>
  <div
    v-animate="{
      enterClass: 'animate__fadeIn',
      leaveClass: 'animate__fadeOut',
      leaveDelay: '200ms',
    }"
    class="register-box"
  >
    <div class="login-info">
      <div class="login-form-title">
        <!-- <img src="@/assets/login/logo.png" alt="" /> -->
        <img :src="loginInfo.logo" alt="" />
        <!-- <span class="line">|</span> -->
        <!-- <span>远程视频协作会商系统</span> -->
        <!-- <span>{{ loginInfo.name }}</span> -->
      </div>
      <el-form
        ref="form"
        :model="form"
        :rules="ruleForm"
        status-icon
        class="login-form"
        @submit.native.prevent
      >
        <el-form-item label="" prop="mobile">
          <div class="inputName">手机号</div>
          <el-input
            type="text"
            clearable
            v-model="form.mobile"
            placeholder="请输入手机号"
            autocomplete="off"
            class="input1"
          >
            <el-button
              slot="append"
              class="send-code-btn"
              :disabled="isSendingCode"
              type="text"
              @click="sendCaptcha"
            >
              <span v-if="isSendingCode">{{ countdown }}s</span>
              <span v-else>发送</span>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="captcha">
          <div class="inputName">验证码</div>
          <el-input
            type="text"
            clearable
            v-model="form.captcha"
            placeholder="请输入验证码"
            autocomplete="off"
            class="input1"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <div class="inputName">密码</div>
          <el-input
            :type="type"
            clearable
            v-model="form.password"
            auto-complete="off"
            placeholder="请输入密码"
            class="input1"
          >
            <i
              v-if="type === 'password'"
              slot="suffix"
              @click="changePasswordType"
              class="el-input__icon el-icon-lock"
            ></i>
            <i
              v-else
              slot="suffix"
              @click="changePasswordType"
              class="el-input__icon el-icon-unlock"
            >
            </i>
            ></el-input
          >
        </el-form-item>
        <el-form-item label="" prop="confirm_password">
          <div class="inputName">确认密码</div>
          <el-input
            :type="type1"
            clearable
            v-model="form.confirm_password"
            auto-complete="off"
            placeholder="请输入密码"
            class="input1"
          >
            <i
              v-if="type1 === 'password'"
              slot="suffix"
              @click="changeconfirm_passwordType"
              class="el-input__icon el-icon-lock"
            ></i>
            <i
              v-else
              slot="suffix"
              @click="changeconfirm_passwordType"
              class="el-input__icon el-icon-unlock"
            >
            </i>
            ></el-input
          >
        </el-form-item>
        <el-form-item>
          <el-button
            v-waves="{ className: 'waves-light' }"
            class="login-btn"
            type="primary"
            @click="submit"
            :loading="logining"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="register-btn-box">
        重置成功后进行登录，<span class="register-btn" @click="OnRegister"
          >去登录</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { send, user_resetpwd } from "@/api/user.js";
export default {
  components: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.confirm_password !== "") {
          this.$refs.form.validateField("confirm_password");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        mobile: "",
        captcha: "",
        password: "",
        confirm_password: "",
      },
      loginInfo: JSON.parse(localStorage.getItem("loginInfo")),
      ruleForm: {
        // username: [
        //   { required: true, message: "请输入用户名", trigger: "blur" },
        //   {
        //     pattern: /^[0-9a-zA-Z]{5,12}$/,
        //     message: "请输入5-12位的英文或数字",
        //     trigger: "blur",
        //   },
        // ],
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            // pattern: /^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/, //座机或者手机验证
            //手机号验证
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "blur",
          },
        ],

        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[0-9a-zA-Z]{5,12}$/,
            message: "请输入5-12位的英文或数字",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        confirm_password: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            pattern: /^[0-9a-zA-Z]{5,12}$/,
            message: "请输入5-12位的英文或数字",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      logining: false,
      type: "password",
      type1: "password",
      isSendingCode: false, // 新增：用于跟踪是否正在发送验证码或倒计时
      countdown: 60, // 新增：倒计时初始值
      countdownTimer: null, // 新增：用于存储倒计时定时器的引用
    };
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    // 组件销毁前清除倒计时定时器
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
    }
  },
  methods: {
    init() {},
    sendCaptcha() {
      const { mobile } = this.form;
      if (!mobile) {
        this.$message.error("请输入手机号");
        return;
      }
      if (this.isSendingCode) {
        return; // 如果已经在发送或倒计时中，则不执行任何操作
      }
      this.isSendingCode = true; // 禁用按钮并开始倒计时
      this.countdown = 60; // 重置倒计时

      const params = {
        mobile: this.form.mobile,
        event: "resetpwd",
      };
      send(params)
        .then((res) => {
          this.startCountdown(); // 启动倒计时
          this.$message({
            message: res.msg,
            type: "success",
            duration: 3000,
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.logining = false;
        });
      // 调用后端API发送验证码
      // this.$axios
      //   .post("/api/send-verification-code", { mobile })
      //   .then((response) => {
      //     this.$message.success("验证码发送成功");
      //     this.startCountdown(); // 启动倒计时
      //   })
      //   .catch((error) => {
      //     this.$message.error("验证码发送失败");
      //     this.isSendingCode = false; // 如果发送失败，则重新启用按钮
      //   });
    },
    startCountdown() {
      this.countdownTimer = setInterval(() => {
        if (this.countdown <= 0) {
          clearInterval(this.countdownTimer); // 清除定时器
          this.isSendingCode = false; // 重新启用按钮
          this.countdown = 60; // 重置倒计时值（可选，根据需求决定是否保留）
        } else {
          this.countdown--; // 更新倒计时值
        }
      }, 1000); // 每秒更新一次倒计时
    },
    submit(event) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.logining = true;
          // const params = {
          //   newpassword: this.form.password,
          //   captcha: this.form.captcha,
          // };
          // 创建表单数据
          const formData = new FormData();
          formData.append("newpassword", this.form.password);
          formData.append("captcha", this.form.captcha);
          // 发送请求
          user_resetpwd(formData)
            .then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
                duration: 2000,
              });
              setTimeout(() => {
                this.$router.push("/login");
              }, 2000);
            })
            .catch((err) => {})
            .finally(() => {
              this.logining = false;
            });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    changePasswordType() {
      this.type = this.type === "password" ? "text" : "password";
    },
    changeconfirm_passwordType() {
      this.type1 = this.type1 === "password" ? "text" : "password";
    },
    OnRegister() {
      // 跳转注册页面
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
.register-box {
  height: 100%;
  width: 100%;
  position: relative;
  @include flex(row, center, center);
  background: url("@/assets/login/video_BG1.png") no-repeat center center / 100%
    100%;

  .login-info {
    width: px2vw(645);
    height: 100%;
    overflow-y: scroll;
    // background: #040404;
    position: fixed;
    right: 0;
    top: 0;
    // padding: px2vh(344) px2vw(114);
    // box-sizing: border-box;
    @include flex(column, center, center);

    .login-form-title {
      width: px2vw(430);
      height: px2vh(56);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: px2vh(60);
      img {
        padding: 0 px2vh(20);
        width: px2vw(278);
        height: px2vh(56);
      }
      .line {
        margin: 0 px2vh(10);
      }
      span {
        @include font4vh(#fff, 26, 400);
      }
    }

    .login-form {
      width: px2vw(420);
      padding: 0 px2vh(20);
      box-sizing: border-box;

      .el-form-item {
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .inputName {
        @include font4vh(#fff, 16, 400);
        line-height: px2vh(28);
      }

      .remember-container {
        display: flex;
        justify-content: space-between;
        .backend-btn {
          // color: var(--clkd-color-global);
          font-family: OpenSans-Regular;
          font-size: 0.14rem;
          font-weight: normal;
          cursor: pointer;
          -webkit-user-select: none;
        }
      }

      .login-btn {
        margin-top: 0.2rem;
        width: 100%;
        height: px2vh(50);
        // background-color: var(--clkd-color-global);
        border: 0;
        @include font4vh(#fff, 14, 400);
      }

      .input1 {
        width: 100%;
        height: px2vh(50);
        margin-top: 0.07rem;
        .el-input__suffix {
          height: 0;
        }

        .el-input__inner {
          height: 100%;
        }

        .el-input-group__append {
          width: 60px;
          text-align: center;
          background: #66b1ff;
          color: #fff;
          .send-code-btn {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .register-btn-box {
    margin-top: px2vh(20);
    font-size: 14px;
    .register-btn {
      color: #66b1ff;
      cursor: pointer;
    }
  }
}
</style>
