<template>
  <!-- 预定会议 -->
  <el-dialog
    :class="['custom-dialog-box', 'wait-meeting-dialog-box', options.size]"
    :title="options.title"
    :visible.sync="options.visible"
    :modal="false"
    :append-to-body="true"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <i v-if="showClose" class="el-icon-close close-btn-mask" @click="close"></i>

    <div
      class="dialog-content-list"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.4)"
      element-loading-text="加载中..."
    >
      <div class="dialog-content-wrap" v-for="item in list" :key="item.id">
        <div class="dialog-content-item">
          <img class="dialog-content-item-img" :src="$baseUrl + item.avatar" />
          <GlobalAutoTooltip
            :content="item.department_name"
            placement="right"
          />
          <GlobalAutoTooltip :content="item.nickname" placement="right" />
          <GlobalAutoTooltip
            :style="{ color: item.status == 1 ? '#67c23a' : '#f56c6c' }"
            :content="item.status_text"
            placement="right"
          />
          <div class="handle-btns" v-if="options.isInMeeting">
            <el-button
              v-if="item.status == 1"
              style="color: #f56c6c"
              type="text"
              @click="handleMeeting(item, 0)"
              >请求离开</el-button
            >
            <el-button
              v-else-if="item.status == 0"
              type="text"
              @click="handleMeeting(item, 1)"
              >允许入会</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="!options.isInMeeting">
      <el-button class="custom-btn red-btn" @click="cancel">离开</el-button>
      <el-button class="custom-btn confirm-btn" @click="confirm"
        >入会</el-button
      >
      <!-- <el-button class="custom-btn cancel-btn" @click="close">取 消</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import {
  meetingWaitingArea,
  meetingWaitingAreaDel,
  meetingWaitingAreaStatus,
} from "@/api/home.js";
export default {
  mixins: [],
  components: {},
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      // list: [],
      loading: false,
    };
  },
  watch: {},
  created() {
    console.log("🚀 ~ created ~ this.options:", this.options);
    // this.init();
  },
  methods: {
    init() {
      this.getList();

      this.intervalId = setInterval(() => {
        this.getList();
      }, window.g.intervalTime || 3000);
    },
    getList() {
      const params = {
        number: this.options.number,
      };
      meetingWaitingArea(params)
        .then((res) => {
          console.log("🚀 ~ meetingWaitingArea ~ res:", res);
          // const list = [];
          // for (let i = 0; i < 20; i++) {
          //   list.push(res.data[0]);
          // }
          // this.list = list;
          this.list = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleMeeting(obj, status) {
      const params = {
        id: obj.id,
        status: status,
      };
      meetingWaitingAreaStatus(params).then((res) => {
        console.log("🚀 ~ meetingWaitingAreaStatus ~ res:", res);
        this.getList();
      });
    },
    confirm() {
      const person = this.list.find((item) => item.user_id == this.userInfo.id);

      if (person && person.status == 1) {
        // 可以入会
        this.$emit("confirm", this.options.meetInfo);
      } else {
        // 不能入会
        this.$message.warning("您未被邀请，请耐心等待");
      }
    },
    cancel() {
      const params = {
        number: this.options.number,
      };
      meetingWaitingAreaDel(params).then((res) => {
        console.log("🚀 ~ meetingWaitingAreaDel ~ res:", res);
        this.options.visible = false;
        this.$emit("close");
      });
    },
    close() {
      this.options.visible = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.wait-meeting-dialog-box {
  .el-dialog__headerbtn {
    display: none;
  }

  .dialog-content {
    flex: 1;
  }

  .dialog-content-list {
    height: 100%;
    overflow: auto;
    margin: 0 px2vh(-10);
    display: flex;
    flex-wrap: wrap;

    .dialog-content-wrap {
      width: 25%;
      padding: px2vh(10);
      box-sizing: border-box;
    }
    .dialog-content-item {
      color: #ffffff;
      padding: px2vh(10);
      box-sizing: border-box;
      border: px2vh(1) solid rgba(89, 175, 249, 0.71);
      border-radius: px2vh(8);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .dialog-content-item-img {
        width: px2vh(60);
        height: px2vh(60);
        border-radius: 50%;
        margin: px2vh(10);
      }

      .dialog-content-item-name {
        font-size: px2vh(16);
        font-weight: 400;
        color: #ffffff;
        margin: px2vh(10);
      }
    }
  }

  .confirm-btn {
    width: 100%;
  }
}
</style>
