import { get, post } from "../services/http";

// 用户注册 /api/user/register
export function register(params = {}) {
  return post(`/api/user/register`, params);
}

// 用户登录 /api/user/login
export function login(params = {}) {
  return post(`/api/user/login`, params);
}

// 注销登录 /api/user/logout
export function logout(params = {}) {
  return post(`/api/user/logout`, params);
}

// 用户列表 /api/user/index
export function user_index(params = {}) {
  return get(`/api/user/index`, params);
}

// 用户列表 /api/user/getlist
export function user_getlist(params = {}) {
  return get(`/api/user/getlist`, params);
}

// 获取用户详情 /api/user/getrow
export function user_getrow(params = {}) {
  return get(`/api/user/getrow`, params);
}

// 添加用户 /api/user/add
export function user_add(params = {}) {
  return get(`/api/user/add`, params);
}

// 编辑用户 /api/user/edit
export function user_edit(params = {}) {
  return get(`/api/user/edit`, params);
}

// 删除用户 /api/user/del
export function user_del(params = {}) {
  return get(`/api/user/del`, params);
}

//视频会议增加短信找回密码涉及接口如下：
// 发送验证码 /api/sms/send
// 请求：post
// 参数1：mobile 值：手机号
// 参数2：event  值：resetpwd

// 重置密码 /api/user/resetpwd
// 请求：post
// 参数1：newpassword 值：新密码
// 参数2： captcha 值：收到的验证码
// 注：重置成功直接退出

// 发送验证码 /api/sms/send
export function send(params = {}) {
  return post(`/api/sms/send`, params);
}
// 重置密码 /api/user/resetpwd
export function user_resetpwd(params = {}) {
  return post(`/api/user/resetpwd`, params);
}

// 站点名称logo /api/department/configs
export function configs(params = {}) {
  return get(`/api/department/configs`, params);
}

// 公司列表  /api/department/companys
export function plistr(params = {}) {
  return post(`/api/department/companys`, params);
}
// 通过公司获取部门 /api/department/departments
export function departments(params = {}) {
  return post(`/api/department/departments`, params);
}

// 添加我的分组 /api/department/addGrouping
export function addGrouping(params = {}) {
  return get(`/api/department/addGrouping`, params);
}

// 修改我的分组 /api/department/udpGrouping
export function udpGrouping(params = {}) {
  return get(`/api/department/udpGrouping`, params);
}

// 删除我的分组 /api/department/delGrouping
export function delGrouping(params = {}) {
  return get(`/api/department/delGrouping`, params);
}

// 获取我的分组 /api/department/getGrouping
export function getGrouping(params = {}) {
  return get(`/api/department/getGrouping`, params);
}

// 下载接口 /api/department/download
export function download(params = {}) {
  return get(`/api/department/download`, params);
}


